var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Signup mx-auto my-16"},[_c('v-card',{staticClass:"d-flex align-start flex-column mx-auto",attrs:{"elevation":"0"}},[_c('h1',{staticClass:"heading",domProps:{"innerHTML":_vm._s(_vm.$t('signupForm.title'))}}),_c('div',{staticClass:"form"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.signup.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email|isEmailUnique"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('signupForm.email'),"required":""},model:{value:(_vm.signupForm.email),callback:function ($$v) {_vm.$set(_vm.signupForm, "email", $$v)},expression:"signupForm.email"}})],1),_c('v-col',{staticClass:"pl-1 d-flex align-center",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"verification-btn",attrs:{"loading":_vm.requestVerificationEmailCodeLoading,"disabled":!valid,"color":"primary"},on:{"click":_vm.requestVerificationEmail}},[_vm._v(" 인증요청 ")])],1)],1)]}}],null,true)}),(_vm.isVerificationEmailCodeRequested)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"9"}},[_c('v-text-field',{ref:"emailCodeInput",attrs:{"label":"이메일 인증코드","placeholder":"이메일로 발송된 인증코드를 입력해주세요","hide-details":""},model:{value:(_vm.signupForm.emailValidationCode),callback:function ($$v) {_vm.$set(_vm.signupForm, "emailValidationCode", $$v)},expression:"signupForm.emailValidationCode"}})],1),_c('v-col',{staticClass:"pl-1 d-flex align-center",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"verification-btn",attrs:{"loading":_vm.verifyEmailCodeLoading,"disabled":!_vm.signupForm.emailValidationCode,"color":"primary"},on:{"click":_vm.verifyEmailCode}},[_vm._v(" 확인 ")])],1)],1):_vm._e(),_c('validation-provider',{attrs:{"name":"password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":_vm.isPasswordVisible ? 'text' : 'password',"error-messages":errors,"label":_vm.$t('signupForm.passwordPlaceholder'),"append-icon":_vm.isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off',"required":""},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.signupForm.password),callback:function ($$v) {_vm.$set(_vm.signupForm, "password", $$v)},expression:"signupForm.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"repassword","rules":"required|repassword:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":_vm.isPasswordVisible ? 'text' : 'password',"error-messages":errors,"label":_vm.$t('signupForm.repassword'),"append-icon":_vm.isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off',"required":""},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.signupForm.repassword),callback:function ($$v) {_vm.$set(_vm.signupForm, "repassword", $$v)},expression:"signupForm.repassword"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"username","rules":"required|userName|isUserNameUnique|isUserNameAllowable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('signupForm.username'),"required":""},model:{value:(_vm.signupForm.userName),callback:function ($$v) {_vm.$set(_vm.signupForm, "userName", $$v)},expression:"signupForm.userName"}})]}}],null,true)}),(!_vm.isReferralLinkVerified)?_c('validation-provider',{attrs:{"name":"referralCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('signupForm.referrerPlaceholder')},model:{value:(_vm.signupForm.referralCode),callback:function ($$v) {_vm.$set(_vm.signupForm, "referralCode", $$v)},expression:"signupForm.referralCode"}})],1),_c('v-col',{staticClass:"pl-1 d-flex align-center",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"inquiry-btn",attrs:{"small":"","disabled":_vm.signupForm.referralCode ? false : true,"loading":_vm.inquiryLoading,"color":"primary"},on:{"click":_vm.inquiryReferralCode}},[_vm._v(" "+_vm._s(_vm.$t('signupForm.referrerInquiryBtn'))+" ")])],1)],1)]}}],null,true)}):_vm._e(),(!_vm.isReferralLinkVerified)?_c('div',{staticClass:"mt-2 ml-1"},[_c('p',{staticClass:"text-sm font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('signupForm.referrerGuideMsg'))+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}}),_c('div',{staticClass:"term-text"},[_c('router-link',{staticClass:"open-dialog",attrs:{"to":{
									name: 'term',
									params: {
										type: 'service-next',
									},
								}}},[_vm._v(" "+_vm._s(_vm.$t('termsOfService.title'))+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(_vm.$t('or.title'))+" ")]),_c('router-link',{staticClass:"open-dialog",attrs:{"to":{
									name: 'term',
									params: {
										type: 'privacy',
									},
								}}},[_vm._v(" "+_vm._s(_vm.$t('privacyTerms.title'))+" ")])],1)],1),_c('v-btn',{staticClass:"button full-width",attrs:{"type":"submit","width":"100%","color":"primary","disabled":!valid || !_vm.checkbox || !_vm.isReferralCodeVerified,"loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('signupForm.submit'))+" ")])],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }