<template>
	<div class="Signup mx-auto my-16">
		<v-card class="d-flex align-start flex-column mx-auto" elevation="0">
			<h1 class="heading" v-html="$t('signupForm.title')"></h1>
			<div class="form">
				<validation-observer ref="observer" v-slot="{ valid }">
					<form @submit.prevent="signup" ref="form">
						<validation-provider
							v-slot="{ errors, valid }"
							name="email"
							rules="required|email|isEmailUnique"
						>
							<v-row no-gutters>
								<v-col cols="9" class="pr-1">
									<v-text-field
										v-model="signupForm.email"
										:error-messages="errors"
										:label="$t('signupForm.email')"
										required
									/>
								</v-col>
								<v-col cols="3" class="pl-1 d-flex align-center">
									<v-btn
										@click="requestVerificationEmail"
										:loading="requestVerificationEmailCodeLoading"
										:disabled="!valid"
										color="primary"
										class="verification-btn"
									>
										인증요청
									</v-btn>
								</v-col>
							</v-row>
						</validation-provider>

						<v-row no-gutters v-if="isVerificationEmailCodeRequested">
							<v-col cols="9" class="pr-1">
								<v-text-field
									ref="emailCodeInput"
									v-model="signupForm.emailValidationCode"
									label="이메일 인증코드"
									placeholder="이메일로 발송된 인증코드를 입력해주세요"
									hide-details
								/>
							</v-col>
							<v-col cols="3" class="pl-1 d-flex align-center">
								<v-btn
									@click="verifyEmailCode"
									:loading="verifyEmailCodeLoading"
									:disabled="!signupForm.emailValidationCode"
									color="primary"
									class="verification-btn"
								>
									확인
								</v-btn>
							</v-col>
						</v-row>

						<validation-provider
							v-slot="{ errors }"
							name="password"
							rules="required|password"
						>
							<v-text-field
								v-model="signupForm.password"
								:type="isPasswordVisible ? 'text' : 'password'"
								:error-messages="errors"
								:label="$t('signupForm.passwordPlaceholder')"
								:append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="isPasswordVisible = !isPasswordVisible"
								required
							/>
						</validation-provider>

						<validation-provider
							v-slot="{ errors }"
							name="repassword"
							rules="required|repassword:@password"
						>
							<v-text-field
								v-model="signupForm.repassword"
								:type="isPasswordVisible ? 'text' : 'password'"
								:error-messages="errors"
								:label="$t('signupForm.repassword')"
								:append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="isPasswordVisible = !isPasswordVisible"
								required
							/>
						</validation-provider>

						<validation-provider
							v-slot="{ errors }"
							name="username"
							rules="required|userName|isUserNameUnique|isUserNameAllowable"
						>
							<v-text-field
								v-model="signupForm.userName"
								:error-messages="errors"
								:label="$t('signupForm.username')"
								required
							/>
						</validation-provider>

						<validation-provider
							v-slot="{ errors }"
							name="referralCode"
							v-if="!isReferralLinkVerified"
						>
							<v-row no-gutters>
								<v-col cols="9">
									<v-text-field
										v-model="signupForm.referralCode"
										:error-messages="errors"
										:label="$t('signupForm.referrerPlaceholder')"
									/>
								</v-col>
								<v-col cols="3" class="pl-1 d-flex align-center">
									<v-btn
										small
										@click="inquiryReferralCode"
										:disabled="signupForm.referralCode ? false : true"
										:loading="inquiryLoading"
										color="primary"
										class="inquiry-btn"
									>
										{{ $t('signupForm.referrerInquiryBtn') }}
									</v-btn>
								</v-col>
							</v-row>
						</validation-provider>
						<div class="mt-2 ml-1" v-if="!isReferralLinkVerified">
							<p class="text-sm font-weight-bold">
								{{ $t('signupForm.referrerGuideMsg') }}
							</p>
						</div>
						<div class="d-flex align-center">
							<v-checkbox v-model="checkbox" />
							<div class="term-text">
								<router-link
									class="open-dialog"
									:to="{
										name: 'term',
										params: {
											type: 'service-next',
										},
									}"
								>
									{{ $t('termsOfService.title') }}
								</router-link>
								<span class="mx-1">
									{{ $t('or.title') }}
								</span>
								<router-link
									class="open-dialog"
									:to="{
										name: 'term',
										params: {
											type: 'privacy',
										},
									}"
								>
									{{ $t('privacyTerms.title') }}
								</router-link>
							</div>
						</div>
						<v-btn
							class="button full-width"
							type="submit"
							width="100%"
							color="primary"
							:disabled="!valid || !checkbox || !isReferralCodeVerified"
							:loading="loading"
						>
							{{ $t('signupForm.submit') }}
						</v-btn>
					</form>
				</validation-observer>
			</div>
		</v-card>
	</div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import i18n from '@/i18n'

import store from '@/store'
import { useRouter } from '@core/utils'
import { successSwal, warningSwal } from '@/plugins/swalMixin'

import AuthService from '@/services/AuthService'

export default {
	components: {},
	setup() {
		const { route, router } = useRouter()
		const checkbox = ref(false)
		const loading = ref(false)
		const inquiryLoading = ref(false)
		const isReferralCodeInquired = ref(false)
		const isReferralLinkVerified = ref(false)
		const isPasswordVisible = ref(false)
		const isVerificationEmailCodeRequested = ref(false)
		const requestVerificationEmailCodeLoading = ref(false)
		const verifyEmailCodeLoading = ref(false)
		const referralCode = route.value.query.referral_code
		const signupForm = ref({
			email: '',
			userName: '',
			referralCode: null,
			password: '',
			repassword: '',
			emailValidationCode: '',
		})

		const isReferralCodeVerified = computed(() => {
			if (signupForm.value.referralCode !== null) {
				return !!isReferralCodeInquired.value
			} else {
				return true
			}
		})

		watch(
			() => referralCode,
			async currentValue => {
				if (currentValue) {
					try {
						const data = await AuthService.inquiryReferralCode(currentValue)
						if (data.canBeRecommender) {
							isReferralLinkVerified.value = true
							isReferralCodeInquired.value = true
							signupForm.value.referralCode = currentValue
						}
					} catch (e) {
						const confirm = await warningSwal({
							html:
								e.response.status === 400
									? e.response.data.message
									: '추천인 코드를 조회하는데 문제가 발생되었습니다',
							allowOutsideClick: false,
						})
						if (confirm.isConfirmed) {
							router.push({ name: 'signup' })
						}
					}
				}
			},
			{
				immediate: true,
			},
		)

		const requestVerificationEmail = async () => {
			requestVerificationEmailCodeLoading.value = true
			try {
				await AuthService.generateAuthCodeEmail(signupForm.value.email)
				isVerificationEmailCodeRequested.value = true
			} catch (e) {
				warningSwal('인증코드를 발송하는데 문제가 발생되었습니다')
			} finally {
				requestVerificationEmailCodeLoading.value = false
			}
		}

		const verifyEmailCode = async () => {
			if (signupForm.value.emailValidationCode === '') {
				warningSwal('인증코드를 입력하세요')
				return
			}

			verifyEmailCodeLoading.value = true
			try {
				await AuthService.validateAuthCodeEmail(
					signupForm.value.email,
					signupForm.value.emailValidationCode,
				)
				isVerificationEmailCodeRequested.value = false

				successSwal('인증되었습니다')
			} catch (e) {
				warningSwal('인증하는데 문제가 발송되었습니다')
			} finally {
				verifyEmailCodeLoading.value = false
			}
		}

		const inquiryReferralCode = async () => {
			try {
				inquiryLoading.value = true
				const data = await AuthService.inquiryReferralCode(
					signupForm.value.referralCode,
				)

				if (data.canBeRecommender) {
					isReferralCodeInquired.value = true
					successSwal({
						title: i18n.t('signupForm.referrerInquiryMsg.success.title'),
						html: i18n.t('signupForm.referrerInquiryMsg.success.html', {
							name: data.uid,
						}),
						confirmButtonText: i18n.t(
							'signupForm.referrerInquiryMsg.success.confirmButtonText',
						),
					})
				} else {
					warningSwal({
						title: i18n.t(
							'signupForm.referrerInquiryMsg.unAvaliableRecommender.title',
						),
						html: i18n.t(
							'signupForm.referrerInquiryMsg.unAvaliableRecommender.html',
							{
								name: data.uid,
							},
						),
						confirmButtonText: i18n.t(
							'signupForm.referrerInquiryMsg.unAvaliableRecommender.confirmButtonText',
						),
						allowOutsideClick: true,
					})
				}
			} catch (e) {
				warningSwal({
					title: i18n.t('signupForm.referrerInquiryMsg.fail.title'),
					html: i18n.t('signupForm.referrerInquiryMsg.fail.html'),
					confirmButtonText: i18n.t(
						'signupForm.referrerInquiryMsg.fail.confirmButtonText',
					),
					allowOutsideClick: true,
				})
			} finally {
				inquiryLoading.value = false
			}
		}

		const trimObjValue = obj => {
			return Object.keys(obj).reduce((acc, curr) => {
				acc[curr] = obj[curr]?.trim() || ''
				return acc
			}, {})
		}

		const signup = async () => {
			try {
				let payload = { ...signupForm.value }

				delete payload.repassword
				payload = trimObjValue(payload)

				await AuthService.signupItmarketerEmail(payload)

				const confirm = await successSwal({
					title: i18n.t('signupForm.submitMsg.success.title'),
					html: i18n.t('signupForm.submitMsg.success.html'),
					confirmButtonText: i18n.t(
						'signupForm.submitMsg.success.confirmButtonText',
					),
				})
				if (confirm.isConfirmed) {
					router.push({ name: 'main' })
				}
			} catch (e) {
				if (e) {
					warningSwal({
						title: i18n.t('signupForm.submitMsg.fail.title'),
						html:
							e.response.status === 400
								? e.response.data.message
								: i18n.t('signupForm.submitMsg.fail.html'),
					})
				}
			}
		}

		store.dispatch('app/setIsLogoWhite', false)

		return {
			isPasswordVisible,
			inquiryLoading,
			isReferralCodeVerified,
			isReferralLinkVerified,
			isVerificationEmailCodeRequested,
			requestVerificationEmailCodeLoading,
			verifyEmailCodeLoading,

			signup,
			verifyEmailCode,
			inquiryReferralCode,
			requestVerificationEmail,

			checkbox,
			signupForm,
			loading,
		}
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.v-main__wrap {
		background-color: white !important;
	}
}

.full-width {
	width: 100%;
	max-width: 100%;
}

.open-dialog {
	font-weight: 600;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}

.Signup {
	padding-top: 36px;
	padding-left: 20px;
	padding-right: 20px;

	.v-card {
		padding-top: 60px;
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 60px;

		width: 480px;
		max-width: 480px;
		border: 1px solid #e4e5ed;
		width: 100%;

		.form {
			width: 100%;
		}

		@media screen and (max-width: 768px) {
			padding: 22px;
		}

		@media screen and (max-width: 480px) {
			width: 100%;
		}
	}

	h1.heading {
		font-size: 27px;
		font-weight: normal;
		color: #303441;
		line-height: normal;
		margin-bottom: 20px;
		margin-top: 0;

		@media screen and (max-width: 768px) {
			font-size: 20px;
		}
	}

	.v-btn[type='submit'] {
		text-transform: none !important;
		letter-spacing: normal !important;
		font-size: 18px !important;
		font-weight: 700 !important;
		height: 52px !important;
		width: 400px;

		&:disabled {
			cursor: not-allowed;
			background-color: rgb(228, 229, 237) !important;
			border-color: rgb(228, 229, 237);
			color: rgb(154, 155, 167) !important;
		}
	}

	.term-text {
		font-size: 13px;
		word-break: keep-all;
	}

	.inquiry-btn,
	.validation-btn {
		width: 100%;
		height: 36px !important;
	}
}
.error {
	color: #f00;
}
</style>
